/* stylelint-disable no-descending-specificity */

/* stylelint-disable max-nesting-depth */

/* stylelint-disable font-family-no-missing-generic-family-keyword */

// menu.scss

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  width: $leftbar-width;
  position: fixed;
  top: 0;

  span.logo-lg {
    display: block;
  }

  span.logo-sm {
    display: none;
  }

  &.logo-light {
    display: $logo-light-display;
  }

  &.logo-dark {
    display: $logo-dark-display;
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//remove margin for in app webview for app2
.wrapper .content-page.content-app2-webview {
  margin-top: 0;
  margin-left: 0 !important;
}

//Content Page
.content-page {
  // margin-left: $leftbar-width;
  overflow: hidden;
  margin-top: $topbar-height;
  padding: 40px 19px 40px 35px;
  min-height: calc(100vh - #{$topbar-height}); // fallback
  min-height: calc(var(--bs-vh100) - #{$topbar-height});
  margin-left: 70px;
  transition: margin-top .2s ease-in-out;

  &.search-space-animation {
    margin-top: 6.5rem;
  }

  &.search-input-focus-space-animation {
    margin-top: 0;
    padding-top: 3.8rem;
  }
}

.content-page.test-content-page {
  // padding-top: 1px;
  padding: 0rem 5.5rem 4.063rem 5.5rem;
  margin-left: 0px !important;
}

.content-page-blur {
  position: fixed;
  background-color: #444444;
  opacity: .6;
  height: 100%;
  z-index: 4;
  width: 100%;
}

// Left Side-menu (Default dark)
.leftside-menu {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  width: $leftbar-width;
  z-index: 10;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: $topbar-height;
  box-shadow: 19px 0px 24px 0px #0000000a;
  background: linear-gradient(270deg, #ffffff 79.44%, #f8f5ff 100%);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .leftbar-user {
    min-height: 90px;
    background: #f1edff;
    padding: 0 20px;
  }
}

// Side-nav
.side-nav {
  padding-left: 0;
  list-style-type: none;
  margin-top: 14px;
  padding-bottom: 1.25rem;
  height: calc(98vh - 50px);

  ul {
    list-style-type: none;
  }

  .side-nav-link {
    color: $menu-item !important;
    display: flex;
    word-break: break-word;
    transition: all .4s;
    list-style: none;
    height: 60px;

    &:hover {
      color: $menu-item-hover;
      text-decoration: none;

      div.menuitem-icon.active {
        padding-right: 0px;
      }

      .menuitem-icon {
        float: left;
        width: 50px;
        height: 50px;
        margin: 5px 10px;
        display: flex;
        align-items: center;
      }
    }

    img {
      margin: 9px 8px 8px 7px;
    }

    .menuitem-icon {
      float: left;
      width: 50px;
      height: 50px;
      margin: 5px 10px;
      display: flex;
      align-items: center;

      .primary-image {
        display: inline;
        max-width: 36px;
        margin: 0 auto;
      }

      .primary-image.active {
        background: linear-gradient(180deg, #ab7ffe 0%, #754ae2 100%);
        border-radius: 35px;
        padding: 9px 7px 5px 7px;
        margin: 0px;
        z-index: 5;
        max-width: 50px;
      }
    }

    div.menuitem-icon.active:after {
      content: '';
      display: block;
      position: absolute;
      border: 35px solid white;
      border-left-color: transparent;
      border-top-color: transparent;
      transform: translate(2%, 0%) rotate(-45deg);
      border-radius: 50%;
    }

    .uil-angle-right {
      float: right;
      margin-top: 21px;
      margin-right: 0rem;
    }

    .uil-angle-right.active {
      transform: rotate(90deg);
      margin-top: .5rem;
      margin-left: -.8rem;
    }

    span {
      float: left;
      padding-top: 20px;
    }

    span.active {
      color: #8c69ff;
      font-weight: 600;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 0;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  .side-nav-second-link {
    color: $menu-item !important;
    margin-left: 30px;

    .menuitem-icon {
      float: left;
    }

    span {
      float: left;
      margin: .4375rem;
    }

    span.active {
      color: #8c69ff;
      font-weight: 600;
    }
  }
  // .collapse>li {
  // height: 70px;
  // padding-top: 10px;
  // }

  .menu-arrow {
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    font-size: 1.1rem;
    transform: translate(0, 0);
    line-height: 3.6rem;

    &:before {
      content: '\F0142';
    }
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    // height: 70px;
    display: flex;
    flex-direction: column;

    > a[aria-expanded='true'] {

      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }

    &.menuitem-active {

      > a:not(.collapsed) {

        > span.menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: .6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-bold;
  }
}

@include media-breakpoint-down(md) {

  .side-nav {
    height: calc(90vh - 205px);
  }
}

.menuitem-active {
  display: inline-block;

  img {
    margin: 15px;
  }

  > a {
    color: $menu-item-active;
  }
}

[dir='rtl'] .side-nav {

  .menu-arrow {

    &:before {
      content: '\F0141';
      display: none;
    }
  }
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  padding-left: 0;

  li {

    a {
      padding: 8px 30px 8px 65px;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all .4s;
      font-size: .89rem;

      &:focus,
      &:hover {
        color: $menu-item-hover;
      }

      .menu-arrow {
        line-height: 1.3rem;
      }
    }

    &.active {

      > a {
        color: $menu-item-active;
      }
    }
  }
}

.side-nav-third-level {

  li {

    a {
      padding: 8px 30px 8px 80px;
    }
  }
}

.side-nav-forth-level {

  li {

    a {
      padding: 8px 30px 8px 100px;
    }
  }
}

body[data-leftbar-compact-mode='fixed']:not(.authentication-bg) {
  overflow: hidden !important;

  .side-nav {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .side-nav .side-nav-link:hover {
    background-color: #f1edff;
  }
}

// Condensed menu
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) {

  .side-nav-div {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    width: 84px;
  }

  .wrapper {
    // Side menu
    .leftside-menu {
      width: 70px;
      z-index: 5;
      padding-top: $topbar-height;
      background: linear-gradient(270deg, #ffffff 28.57%, #f8f5ff 100%);

      .simplebar-mask,
      .simplebar-content-wrapper {
        overflow: visible !important;
      }

      .simplebar-scrollbar {
        display: none !important;
      }

      .simplebar-offset {
        bottom: 0 !important;
      }

      .logo {
        width: 70px;
        z-index: 1;
        background: $bg-leftbar;
      }
    }

    // Help Box
    .help-box {
      display: none;
    }

    .content-page {
      margin-left: 70px;
    }
    //Navbar & Footer
    .navbar-custom,
    .footer {
      left: 70px;
    }
  }

  // Sidebar Menu
  .side-nav {

    .side-nav-title,
    .badge,
    .menu-arrow {
      display: none !important;
    }

    .collapse,
    .collapsing {
      display: none;
      height: inherit !important;
      transition: none !important;

      .side-nav-second-level,
      .side-nav-third-level,
      .side-nav-forth-level {
        display: none !important;
      }
    }

    .side-nav-item {
      position: relative;
      white-space: nowrap;

      .side-nav-link {
        min-height: 60px;
        transition: none;
        padding: 0px;

        &:hover,
        &:active,
        &:focus {
          color: $menu-item-hover;

          .menuitem-icon {
            border-radius: 35px;
          }
        }

        .menuitem-icon {
          float: left;
        }

        i {
          font-size: 1.125rem;
          margin-right: 20px;
          margin-left: 6px;
        }

        span {
          display: none;
        }
      }
    }
  }

  .logo {

    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
      line-height: 70px;
      color: $primary;
    }
  }
}

.wquiz-wrapper {

  .content-page {
    margin-top: 0px;
    padding: 0 0 0 0 !important;
  }
}

@include media-breakpoint-down(md) {

  body {
    overflow-x: hidden;
  }

  .leftside-menu {
    box-shadow: $box-shadow;
    z-index: 11;

    .leftbar-user {
      display: block;
    }
  }

  .sidebar-enable {

    .leftside-menu {
      display: block;
    }
  }

  .navbar-nav.navbar-right {
    float: right;
  }

  .content-page {
    margin-right: 0px !important;
    margin-left: 0 !important;
    min-height: calc(var(--bs-vh100) - 70px - 254px);
    padding: 20px 3px 0px !important;
  }

  .content-page.footer-hidden {
    padding-top: 20px !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
    min-height: calc(var(--bs-vh100) - 70px - 254px);
  }

  body[data-leftbar-compact-mode='condensed'] {

    .leftside-menu {
      margin-left: 0px;
      display: none;
    }
  }

  .logo {

    span.logo-lg {
      display: block;
    }

    span.logo-sm {
      display: none;
    }
  }
}

/// Help-box
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: $help-box-light-bg;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

// Light
body[data-leftbar-theme='light'] {

  .help-box {
    background-color: $primary;
  }

  .logo {

    &.logo-light {
      display: $logo-dark-display;
    }

    &.logo-dark {
      display: $logo-light-display;
    }
  }

  .leftside-menu {
    background: $bg-leftbar-light;

    .logo {
      background: $bg-leftbar-light !important;
    }
  }

  .side-nav {

    .side-nav-link {
      color: $menu-item-light;

      &:hover,
      &:focus,
      &:active {
        color: $menu-item-light-hover;
      }
    }

    .menuitem-active {

      > a {
        color: $menu-item-dark-active !important;
      }
    }

    .side-nav-title {
      color: $menu-item-light;
    }

    .side-nav-second-level,
    .side-nav-third-level,
    .side-nav-forth-level {

      li {

        a {
          color: $menu-item-light;

          &:focus,
          &:hover {
            color: $menu-item-light-hover;
          }
        }

        &.active {

          > a {
            color: $menu-item-light-active;
          }
        }
      }
    }
  }

  // Enlarge menu
  &[data-leftbar-compact-mode='condensed'] {
    // Sidebar Menu
    .side-nav {

      .side-nav-item {

        .side-nav-link {

          &:hover,
          &:active,
          &:focus {
            color: $menu-item-hover;
          }
        }

        &:hover {

          .side-nav-link {
            background: $primary;
            color: $white !important;
            transition: none;
          }

          > ul {
            background: $bg-leftbar-light;
            box-shadow: $box-shadow;

            a {

              &:hover {
                color: $menu-item-light-hover;
              }
            }
          }

          > .collapse,
          > .collapsing {

            > ul {
              background: $bg-leftbar-light;

              a {

                &:hover {
                  color: $menu-item-light-hover;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Layout -topnav
body[data-layout='topnav'] {

  .content-page {
    margin-left: 0 !important;
    padding: 0 0 60px 0;
  }
}

// Layout-boxed
body[data-layout-mode='boxed'] {
  background-color: $boxed-layout-bg;

  .wrapper {
    max-width: $boxed-layout-width;
    margin: 0 auto;
    background-color: $body-bg;
    box-shadow: $box-shadow;
  }

  &[data-leftbar-compact-mode='condensed'] {

    .logo {
      position: relative;
      margin-top: -$topbar-height;
    }
  }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {

  body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) {
    padding-bottom: 0;

    .wrapper {
      display: flex;
    }

    .leftside-menu {
      position: relative;
      min-width: $leftbar-width;
      max-width: $leftbar-width;
      padding-top: 0;
    }

    .logo {
      position: relative;
      margin-top: 0;
    }

    .content-page {
      margin-left: 0;
      width: 100%;
      padding-bottom: 60px;
    }
  }
}

// Detached Left sidebar

body[data-layout='detached'] {
  padding-bottom: 0;

  @include media-breakpoint-up(lg) {

    .container-fluid {
      max-width: 95%;
    }
  }

  @include media-breakpoint-down(md) {

    &.sidebar-enable {

      .leftside-menu {
        left: 0;
        overflow-y: auto;
        margin-top: 70px;
      }
    }

    .wrapper {
      max-width: 100%;
    }

    .content-page {
      margin-left: 0 !important;
      padding: 0 10px 60px 10px;
    }

    .lang-switch {
      display: none;
    }

    &[data-leftbar-compact-mode='condensed'] {

      &.sidebar-enable {

        .leftside-menu {
          margin-top: 0px;
        }
      }
    }
  }

  &[data-layout-mode='boxed'] {

    .wrapper {
      max-width: 100%;
    }
  }

  .wrapper {
    display: flex;
    overflow: inherit;
  }

  .content-page {
    margin-left: 0;
    overflow: hidden;
    // padding: 0 15px 5px 30px;
    padding: 20px;
    position: relative;
    // margin-right: -15px;
    width: 100%;
    padding-bottom: 60px;
  }

  .leftside-menu {
    position: relative;
    background: $bg-detached-leftbar !important;
    min-width: $leftbar-width;
    max-width: $leftbar-width;
    box-shadow: $box-shadow;
    // margin-top: 30px;
    padding-top: 0 !important;
    z-index: 1001 !important;

    .side-nav {

      .side-nav-link {
        color: $menu-item-dark !important;

        &:hover,
        &:focus,
        &:active {
          color: $menu-item-dark-hover !important;
        }
      }

      .side-nav-second-level,
      .side-nav-third-level,
      .side-nav-forth-level {

        li {

          a {
            color: $menu-item-dark;

            &:focus,
            &:hover {
              color: $menu-item-dark-hover;
            }
          }

          &.mm-active {

            > a {
              color: $menu-item-dark-active;
            }
          }
        }
      }

      .menuitem-active {

        > a {
          color: $menu-item-dark-active !important;
        }
      }

      .side-nav-title {
        color: $menu-item-dark;
      }
    }
  }

  .leftbar-user {
    padding: 30px 20px;
    text-align: center;

    .leftbar-user-name {
      font-weight: 700;
      color: $dark;
      margin-left: 12px;
      margin-top: 8px;
      display: block;
    }
  }

  // Enlarge menu
  &[data-leftbar-compact-mode='condensed'] {

    .wrapper {
      // Side menu
      .leftside-menu {
        max-width: 70px;
        min-width: 70px;
        position: relative;
      }

      .leftbar-user {
        display: none;
      }

      // Content Page
      .content-page {
        margin-left: 0;
      }

      //Footer
      .footer {
        left: 0;
      }
    }

    // Sidebar Menu
    .side-nav {

      .side-nav-item {

        .side-nav-link {

          &:hover,
          &:active,
          &:focus {
            color: $menu-item-hover;
          }
        }

        &:hover {

          .side-nav-link {
            background: $primary;
            color: $white !important;
            transition: none;
          }

          > ul {
            background: $bg-leftbar-light;
            box-shadow: $box-shadow;

            a {

              &:hover {
                color: $menu-item-light-hover;
              }
            }
          }
        }
      }
    }
  }
}

// reaponsive detached mode
@include media-breakpoint-up(lg) {

  body[data-layout='detached'] {

    &[data-leftbar-compact-mode='scrollable'] {

      .wrapper {
        padding-top: 70px;
      }
    }
  }
}

.button-menu-mobile {

  .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all .5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }

  span {
    height: 2px;
    width: 100%;
    background-color: rgba($white, .8);
    display: block;
    margin-bottom: 5px;
    transition: transform .5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}

// Light sidebar

// Dark sidenav
body[data-leftbar-theme='dark'] {

  .leftside-menu {
    background: $bg-leftbar-dark;

    .logo {
      background: $bg-leftbar-dark !important;
    }
  }

  .side-nav {

    .side-nav-link {
      color: $sidebar-dark-menu-item;

      &:hover,
      &:focus,
      &:active {
        color: $sidebar-dark-menu-item-hover;
      }
    }

    .menuitem-active {

      > a {
        color: $sidebar-dark-menu-item-active !important;
      }
    }

    .side-nav-title {
      color: $sidebar-dark-menu-item;
    }

    .side-nav-second-level,
    .side-nav-third-level,
    .side-nav-forth-level {

      li {

        a {
          color: $sidebar-dark-menu-item;

          &:focus,
          &:hover {
            color: $sidebar-dark-menu-item-hover;
          }
        }

        &.active {

          > a {
            color: $sidebar-dark-menu-item-active;
          }
        }
      }
    }
  }

  // Enlarge menu
  &[data-leftbar-compact-mode='condensed'] {
    // Sidebar Menu
    .side-nav {

      .side-nav-item {

        .side-nav-link {

          &:hover,
          &:active,
          &:focus {
            color: $sidebar-dark-menu-item-hover;
          }
        }

        &:hover {

          .side-nav-link {
            background: $primary;
            color: $white !important;
            transition: none;
          }

          > ul {
            background: $bg-leftbar-dark;
            box-shadow: $box-shadow;

            a {

              &:hover {
                color: $sidebar-dark-menu-item-hover;
              }
            }
          }

          > .collapse,
          > .collapsing {

            > ul {
              background: $bg-leftbar-dark;
            }
          }
        }
      }
    }
  }
}

// Logout menu item
.logout-menu-item {
  border-top: #dfdfdf solid 1px;

  span {
    color: #ff5959;
  }
}

.profile-section-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span,
  i {
    font-size: 1.3rem;
    color: #444444;
  }
}

.side-nav .side-method-nav-item {
  margin-left: 0px 0px 0px 40px;

  span {
    font-weight: 500;
  }

  img {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 10px 0px 10px 70px;
  }
}
