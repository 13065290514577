.DoubtsOnChat {
  margin-left: -35px;
  margin-right: -19px;
  margin-top: -40px;
  height: calc(var(--bs-vh100) - 70px);
  overflow: hidden;
  margin-bottom: -65px;

  @media (max-width: 767.98px) {
    margin: -20px -3px;
  }

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 9999;
    height: 100%;
    margin: 0;
  }
}

.todHome {
  margin-top: -20px;
}

.newTodHome {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media only screen and (min-width: 767.98px) {
  .newTodHome {
    margin-left: -32px !important;
    margin-right: -29px !important;
  }
}

.todHomeWithDoubts {
  margin-top: -16px;
  margin-bottom: 48px;
  color: black;
}

.instalearnContainer {
  margin-top: -32px;
  color: black;
  height: calc(var(--bs-vh100) - 70px);
  overflow: auto;
}

@media only screen and (min-width: 767.98px) {
  .instalearnContainer {
    margin-left: -106px;
    margin-right: -19px;
    margin-top: -37px;
    margin-bottom: -65px;
    color: black;
    height: calc(var(--bs-vh100) - 70px);
    overflow: auto;
  }

  .todHome {
    margin-left: -106px;
    margin-right: -19px;
    margin-top: -37px;
    margin-bottom: -65px;
    color: black;
  }

  .todHomeWithDoubts {
    margin-left: -106px;
    margin-right: -19px;
    margin-top: -37px;
    margin-bottom: -65px;
    color: black;
  }
}

@media only screen and (max-width: 767.98px) {
  .layoutDoc {
    width: 100% !important;
  }

  .askTutorBottomBar {
    z-index: 10 !important;
  }
}
