// 
// breadcrumb.scss

// Breadcrumb item arrow
.breadcrumb-item {
    a{
    color: #888888;
    text-transform: capitalize;
    }
    +.breadcrumb-item {
        padding-left: 0.1rem;
        &::before {
            font-family: "Material Design Icons";
            font-size: 16px;
            line-height: 1.3;
            color: #888888;
            padding-right: 0.1rem;
            content: var(--bs-breadcrumb-divider,"󰅂");
        }
    }
    &.active{
        color: #8C69FF;
    }
}

ol.breadcrumb{
    padding: 0 0 1rem 0;
}