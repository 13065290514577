// 
// pagination.scss
//
// Pagination rounded (Custom)
.pagination {
  padding: 0;
  margin-bottom: 10px;

  .pagination-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .pagination {
      // float: right !important;
      margin-bottom: 0;
      flex-wrap: wrap;

      .page-link {
        border-radius: 4px;
        margin: 0 3px !important;
        border: none !important;
        color: #666666 !important;

        &:focus {
          box-shadow: none;
          background-color: transparent;
        }
      }

      a[role="button"] {
        i {
          color: #7F5CF4 !important;
        }
      }

      .page-item {
        padding: 0px !important;
      }

      .page-item.active {
        .page-link {
          background-color: #F1EDFF;
          color: #7F5CF4 !important;
          padding: 5px 11px;
          border: none;
        }
      }

      button[disabled] {
        color: $gray-500 !important;
      }
    }
  }

  .pagination-info-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    margin-bottom: 0;
    color: #888888;
  }
}

@media (max-width: 767.98px) {
  .pagination {
    .pagination-body {
      padding: 10px;

      .pagination {
        .page-link {
          margin: 0 3px 0 0 !important;
        }
      }
    }
  }
}