/* stylelint-disable */
// TODO - move to styled components
.navbar-custom {
  padding: 0 0.5vw;
  background-color: $bg-topbar;
  border-bottom: 0.0625rem solid rgba(176, 176, 176, 0.3);
  min-height: $topbar-height;
  position: fixed;
  left: $leftbar-width;
  top: 0;
  right: 0;
  z-index: 1001;
  transition: top 0.1s ease-in-out;

  .topbar-left {
    background-color: $bg-topbar;
    height: $topbar-height;
    position: fixed;
    z-index: 1;
    width: $leftbar-width;
    text-align: center;
    top: 0;
    left: 0;

    .logo {
      line-height: $topbar-height;

      i {
        display: none;
      }
    }
  }

  .topbar-menu {
    position: relative;
    z-index: 1;

    & > li {
      float: left;
      max-height: $topbar-height;

      .show {
        &.nav-link {
          color: $gray-700;
        }
      }

      &.search-list {
        @media (min-width: 576px) {
          .dropdown-menu-animated.show {
            top: calc(100% + 0.5rem) !important;
            width: 320px;
            left: auto !important;
            border: 0px;
          }
        }

        .dropdown {
          margin-top: 1.2rem;

          & > a {
            background-color: #f6f6f6;
            border-radius: 0.5rem;
            line-height: 1.8rem;
            width: 2rem;
            height: 2rem;
            text-align: center;
            border: 1px solid blue;
          }
        }
      }
    }

    .nav-link {
      padding: 0;
      min-width: 32px;
      color: #444444;
      display: block;
      text-align: right;
      margin: 0 6px;
      position: relative;
    }
  }

  &.search-input-focus-animation {
    top: -3.75rem;
  }
}

.topbar-dropdown {
  .nav-link {
    line-height: $topbar-height;
  }
}

/* Search */

// topbar-search
.app-search {
  font-family: $font-family-poppins;
  position: static;
  padding: calc(#{$topbar-height - 52px} / 2) 0px
    calc(#{$topbar-height - 52px} / 2.8) 0px;

  @media (min-width: 1200px) {
    .react-select__control,
    .react-select__menu {
      width: 30rem !important;
    }
  }

  .react-select__control,
  .react-select__menu {
    overflow: hidden;
    width: 20rem !important;
  }

  .react-select__input {
    font-weight: bold;
  }

  .react-select__control {
    border: none;
    height: $input-height;
    padding-left: 2.5rem;
    padding-right: 1rem;
    background-color: $bg-topbar-search;
    box-shadow: none;
    min-height: 3.25rem;

    &.react-select__control--is-focused {
      padding-left: 1rem;
    }
  }

  .react-select__menu {
    margin-top: 0;
    z-index: inherit;
    box-shadow: 0 0 0 1px #0000000a, 0 4px 11px #0000000d;

    .react-select__menu-list {
      padding-bottom: 0;
    }

    .react-select__option {
      padding: 0rem 1rem;

      a {
        padding: 1rem 0rem;
        border-bottom: 0.0625rem solid #b0b0b0;

        &:hover,
        &:focus {
          background-color: inherit;
          color: inherit;
        }

        &:not(.dropdown-item) {
          border: none;
        }
      }

      &:last-child a {
        border: none;
      }
    }

    .menu-dropdown-header {
      padding: 0.8rem 1rem;
      color: #888888;
    }
  }

  .react-select__value-container {
    padding: 0;
  }

  span.search-icon {
    position: absolute;
    z-index: 9;
    font-size: 20px;
    line-height: 3.25rem;
    left: 0px;
    top: 0px;
    width: 2.375rem;
    text-align: center;

    svg {
      display: inline-block;
      margin-top: -0.15rem;
    }
  }

  .form-control {
    border: none;
    height: $input-height;
    padding-left: 40px;
    padding-right: 20px;
    background-color: $bg-topbar-search;
    box-shadow: none;
  }

  .input-group-text {
    margin-left: 0;
    z-index: 4;
  }
}

/* Download App */
.mobile-popup {
  display: none;
}

.download-app {
  line-height: 70px;
  vertical-align: middle;

  a {
    color: #444444;
    font-size: 15px;
    font-weight: bold;

    .dl-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-left: 5px;
    }

    .calendar-title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #8c69ff;
    }
  }
}

@media (max-width: 767.98px) {
  .mobile-popup {
    display: block !important;
  }
}

/* Notification */
.notification-list {
  margin-left: 0;
  padding-left: 0.625rem;

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: $topbar-height;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }

  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }

  .topbar-dropdown-menu {
    .notify-item {
      padding: 7px 20px;
    }

    .header-underline {
      margin: 0px;
      width: 110px;
    }

    .switch-item {
      cursor: pointer;
    }

    .account-user-avatar img {
      height: 25px;
      width: 25px;
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  i,
  span {
    vertical-align: middle;
  }
}

.switch-profile-icon {
  border-radius: 50% !important;
  border: 2px solid #d9cdff;
  width: 24px;
  height: 24px;
  text-align: center;
  float: left;
  background-color: #ff5050;
  font-weight: 600;
  color: white;
  margin-right: 5px;
}

.dropdown-toggle {
  &::after {
    margin-top: 1.5rem;
  }

  &:focus-visible {
    background-color: #fff;
  }
}

// .dropdown :hover{
//         background-color:#FFFFFF;
//         color: #444444;
// }

.nav-user {
  padding: calc(#{$topbar-height - 39px} / 2) 50px
    calc(#{$topbar-height - 39px} / 2) 20px !important;

  /*     text-align: left !important;
    position: relative;
    background-color: lighten($gray-100, 2.5%);
    border: 1px solid $gray-100;
    border-width: 0 1px;
    min-height: $topbar-height; */

  .account-user-avatar,
  .nav-arrow {
    position: absolute;
  }

  .nav-arrow {
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .account-user-avatar {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    img {
      border: 2px solid #d9cdff;
      height: 32px;
      width: 32px;
    }
  }

  .account-position {
    display: block;
    margin-top: -3px;
  }

  .account-user-name {
    display: block;
    font-weight: $font-weight-semibold;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 6.25rem;
    white-space: nowrap;
  }
}

.no-wrap {
  white-space: normal !important;
}

.button-menu-mobile {
  border: none;
  color: $dark;
  height: $topbar-height;
  line-height: $topbar-height;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
  z-index: 1;
  position: relative;
  margin-left: -$grid-gutter-width;

  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }

  &.disable-btn {
    display: none;
  }
}

[data-keep-enlarged='true'] {
  .navbar-custom {
    padding-left: 0;
  }

  .button-menu-mobile {
    margin: 0;

    &.disable-btn {
      display: inline-block;
    }
  }
}

.exit-assessment-button {
  padding: calc((#{$topbar-height} - 16px) / 2) 0px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ff5959;
}

@media (max-width: 575px) {
  .navbar-custom {
    .topbar-menu {
      position: initial;
    }
    .dropdown {
      position: static;
      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
        border: 0px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .navbar-custom {
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-custom {
    .topbar-menu {
      .nav-link {
        margin: 0 3px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-custom {
    left: 0 !important;
    padding: 0 10px;
  }

  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }

  .nav-user {
    .account-user-name {
      font-size: 16px;
    }

    .account-position,
    .account-user-name {
      margin-left: 40px;
    }

    .account-user-avatar {
      left: 0;

      img {
        width: 52px;
        height: 52px;
      }
    }
  }

  /* Download App */
  .download-app {
    line-height: 45px;

    a {
      background: #f6f6f6;
      border-radius: 4px;
      display: inline-block;
      width: 240px;
      margin: 20px auto;
    }
  }
}

@media (max-width: 375px) {
  .navbar-custom {
    .topbar-menu {
      .nav-link {
        margin: 0 6px;
      }
    }

    &.topnav-navbar {
      .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
      }

      .navbar-toggle {
        margin: 27px 3px;
      }

      .button-menu-mobile {
        width: auto;
      }
    }
  }
}

// Scrollable Layout

@include media-breakpoint-up(lg) {
  body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) {
    .navbar-custom {
      position: absolute;
    }
  }
}

// Topbar Dark
.topnav-navbar-dark {
  background-color: $bg-topbar-dark;

  .nav-user {
    background-color: lighten($bg-topbar-dark, 5%);
    border: 1px solid lighten($bg-topbar-dark, 7.5%);
  }

  .topbar-menu {
    li {
      .show {
        &.nav-link {
          color: $white !important;
        }
      }
    }
  }

  .app-search {
    .react-select__control {
      background-color: $bg-topbar-dark-search;
      color: $white;
    }

    .react-select__input {
      color: $white;
    }

    span,
    .react-select__placeholder {
      color: $gray-600;
    }
  }

  .navbar-toggle {
    span {
      background-color: rgba($white, 0.8) !important;
    }
  }
}

body[data-layout-mode='boxed'] {
  .navbar-custom {
    position: relative;
    left: 0 !important;
    margin: -$topbar-height (-$grid-gutter-width / 2) 0;
  }

  &[data-layout='topnav'] {
    .navbar-custom {
      margin: 0;
    }
  }
}

.user_guidetour {
  padding-right: 0.5rem;
}

.cal-link {
  background: #f6f6f6;
  border-radius: 0.5rem;
  display: inline-flex !important;
  align-items: center;
  width: 11.063rem;
  height: 2rem;
  justify-content: space-evenly;
}
