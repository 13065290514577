.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #333333;
}
.page-sub-title {
  font-size: 20px;
  line-height: 22px; //137%
  color: #888888;
  font-weight: 400;
  // font-family: Lora;
}

//sections and subsections
.section-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #444444;
  
}
.section-sub-heading {
  font-weight: 400;
  // font-family: Lora;
  font-size: 20px;
  line-height: 30px;
  color: #888888;
}

//breadcrumb
.breadcrumb {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 767.98px) {
  .page-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }
}
