// 
// buttons.scss
//

.btn {
    box-shadow: none !important;
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

.btn-rounded {
    border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}