.alertContainer{
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 0.313rem;
    z-index: 111111;
}

@keyframes animateOpacity{
    from{
        opacity: 0;
    }
    to{
        opacity: 0.8;   
    }
}
.alert{
    border-radius: 0.313rem;
    background-color: #333333;
    opacity: 0.8;
    color: white;
    text-align: center;
    margin: 0.313rem;
    width: 50rem;
    display: inline-block;
    animation: animateOpacity 1s ;
    animation-iteration-count: 1
}


@media (max-width: 767.98px) {
    .alert{
        width: 90%;
        min-width: auto!important;
        font-size: 0.75rem;
    }
}
