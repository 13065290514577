// 
// footer.scss
//

.footer {
  border-top: 1px solid rgba($gray-600, .2);
  bottom: 0;
  padding: 19px 24px 20px;
  position: absolute;
  right: 0;
  color: $gray-600;
  left: $leftbar-width;
  text-align: right; 

  .footer-links {

    a {
      color: $gray-600;
      margin-left: 1.5rem;
      transition: all .4s;

      &:hover {
        color: $gray-900;
        cursor: pointer;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}



.lp-footer:not(.full-width) {
  margin-left: 70px;
}
//for footer in theme for detached layout
.lp-footer {
  margin-top: 10px;
  color: #666666;
  padding: 16px 34px; 
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;

  .footer-links {
    text-align: left;

    a {
      padding: 0 .75rem;
      color: #666666;
      transition: all .4s;

      &:hover {
        color: $gray-900;
        cursor: pointer;
      }

      &:first-of-type {
        padding-left: 0;
      }
    }
        
  }

  .theme-text {
    margin-bottom: 0;
    text-align: right;
  }
}

@include media-breakpoint-down(lg) {

  .footer,
  .lp-footer {
    text-align: center;
        
  }
}

@include media-breakpoint-down(md) {

  .footer {
    left: 0 !important;
  }

  .lp-footer:not(.full-width) {
    margin-left: 0px;
  }

  .lp-footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 2rem 1rem;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;

    .footer-links {
      text-align: center;
      padding-bottom: 1.5rem;

      a {
        display: block;
        padding: .5rem;
        margin: 0 !important;
      }
    }

    .theme-text {
      text-align: center;
    }
  }
    
}


// Used for account authentication pages
.footer-alt {
  left: 0;
  border: none;
  text-align: center;
}

// Layout - Topnav
body[data-layout='topnav'] {

  .footer {
    left: 0 !important;
    padding: 19px 0 20px;
  }

  &[data-layout-mode='boxed'] {

    .footer {
      max-width: $boxed-layout-width;
    }
  }
}

body[data-layout-mode='boxed'] {

  .footer {
    border: none;
    margin: 0 auto;
    background-color: $body-bg;
    box-shadow: $box-shadow;
    max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
  }
    
  &[data-leftbar-compact-mode='condensed'] {

    .footer {
      max-width: calc(#{$boxed-layout-width} - 70px);
    }
  }
}

body[data-layout='detached'] {

  .footer,
  .lp-footer {
    left: 0;

    .container-fluid {
      max-width: 100%;
      padding: 0;
    }
  }
}