.centered{
    display: flex;
    justify-content: center;
}

.error_boundary_container{
    border-radius: 5px;
    padding: 20px;
    height: 80vh;
    margin: 25px;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e2e2e2;
    >div{
        >h2{
            margin-top:30px;
        }
        >p{
            font-size: 20px;
            color: #666666;
        }
        >img{
            width: 400px;
        }
        >button{
            margin-top: 40px;
        }
    }
}

  // Resize for small screens
  @media (max-width:767.98px) {
    .error_boundary_container{
        width: auto;
        border:none;
    }
  }


  // Resize for small screens
  @media (max-width:500px) {
    .error_boundary_container{
        border:none;
        >div{
            >h2{
                margin-top:30px;
                font-size: 20px;
            }
            >p{
                font-size: 16px;
                color: #666666;
            }
            >img{
                width: 200px;
            }
            >button{
                margin-top: 20px;
            }
        }
    }
    
    
  }
