.toast-container {
	max-width: 100%;
	pointer-events: none;
	z-index: 999999;
  }
  
  .toast-container .toast {
	background: rgb(51, 51, 51);
	opacity: 1;
	border-radius: 4px;
	width: 500px;
	text-align: center;
  }