// 
// accordions.scss
//

.custom-accordion {


    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F142";
                }
            }
        }
    }
}

.custom-accordion-title {
    color: $custom-accordion-title-color;
    position: relative;

    &:hover {
        color: lighten($custom-accordion-title-color,7.5%)
    }
}

.accordion {
    >.card {
        >.card-header {
            margin-bottom: -1px;
        }
    }
}