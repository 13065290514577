// 
// general.scss
//
.highlight-button{
  pointer-events: none;
}
html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
    min-height: -webkit-fill-available;
  }
}
// to highlight the component
.highlight-component {
  position: relative;
  z-index: 11111;
}
@media(max-width:575.98px){
  .highlight-component {
    margin-top: -12.5rem;
  }
  .highlight-component-text{
    font-size: 0.875rem;
  }
  .highlight-component-backdrop {
    position: relative;
    top: -4rem;
  }
}
.highlight-component-backdrop {
  // height: 138.2vh;
  overflow: hidden;
}

.highlight-component-backdrop:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1003;
  cursor: pointer;
}

.highlight-component-text{
  z-index: 1003;
  color: #fff;
  text-align: center;
  position: relative;
  cursor: pointer;
}

// Disabling user interaction when offline
body.offline::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111111;
}